import React, { useEffect, useState } from "react";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import Navbar from "/src/containers/navbar";
import { useDispatch } from "react-redux";
import SpaceCompleteContainer from "/src/containers/space-complete";
import { LocalizedRouter as Router } from "gatsby-theme-i18n";
import api from "/src/api";
import { useParams } from "@reach/router";
import { transformSpace, INITIAL_STATE } from "/src/redux/reducers/space-reducer";
import { setCurrentAsset } from "/src/redux/actions/misc-actions";
import Contact from "/src/components/home/contact";
import Footer from "/src/components/footer";
import SetupContainer from "/src/setup-container";

const SpaceComplete = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [space, setSpace] = useState(INITIAL_STATE.data);

  useEffect(async () => {
    const spaceId = params.spaceId ? params.spaceId.substr(0, params.spaceId.indexOf("-")) : "1";
    const response = await api.getSpace(spaceId);
    dispatch(setCurrentAsset(response.data.payload.asset));
    setSpace(transformSpace(response.data.payload));
    // dispatch current asset to set proper styles
  }, []);

  return <SpaceCompleteContainer space={space} />;
};

const SpaceUnindexed = () => {
  return (
    <>
      <SetupContainer />
      <BacThemeProvider>
        <Navbar />
        <Router id="localized-router" basePath="/">
          <SpaceComplete path="space/:spaceId" />
        </Router>
        <Contact />
        <Footer />
      </BacThemeProvider>
    </>
  );
};

export default SpaceUnindexed;
